export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/about-us": [~3],
		"/about-us/careers": [~4],
		"/about-us/locations": [~5],
		"/about-us/our-story": [~6],
		"/about-us/our-team": [~7],
		"/about-us/press": [~8],
		"/capabilities": [~9],
		"/capabilities/partner-with-us": [~10],
		"/news": [~11],
		"/privacy-policy": [~12],
		"/products": [~13],
		"/terms-and-conditions": [~14],
		"/who-we-serve": [~15]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';